/*! Events */

// Next-event
#next-event-container {
  position: relative;
  a.preview-container {
    display: block;
    position: relative;
    color: $white;
    &:focus, &:visited {
      color: $white;
    }
    &:hover {
      color: darken($brand-danger, 10%);
    }
    .title-event {
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      width: 100%;
      font-style: italic;
      padding: 15px;
      font-size: 16px;
      position: absolute;
      background-color: $black-transparent;
    }
  }
  .description-container {
    padding: 15px 30px;
  }
}

// Calendar
.fc-row .fc-content-skeleton {
  padding-bottom: 0;
  tbody {
    display: none;
    visibility: hidden;
  }
}
.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: none;
  text-align: right;
  display: block;
  &.fc-has-event {
    background: $green;
  }
}
.fc-unthemed td.fc-day.fc-today {
  background: darken($black, 10%);
}
.fc-basic-view .fc-body .fc-row {
  min-height: 0;
}
.fc-event {
  @include x-border-radius(0);
  border: 0;
}

// Find-event
#find-event-margin {
  margin-top: 43px;
}
#find-event-container {
  position: relative;
  .icon-container {
    margin-top: 3px;
    text-align: center;
  }
  .title-container {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin-top: 15px;
  }
  form {
    border: 2px solid $white;
    padding: 10px 20px;
    label {
      font-weight: 100;
    }
    input {
      margin-bottom: 20px;
    }
    .btn-container {
      text-align: center;
      margin-top: 20px;
      padding-top: 20px;
    }
  }
}

// Files download
#files-container {
  .icon-container {
    margin-top: 3px;
    text-align: center;
  }
  .title-container {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin-top: 15px;
  }
}

// Panel events
#panel-events-container {
  position: relative;
  a.see-all {
    position: absolute;
    font-size: 12px;
    right: 0;
    top: 10px;
    color: $brand-danger;
    &:focus, &:visited {
      color: $brand-danger;
    }
    &:hover {
      color: darken($brand-danger, 10%);
    }
  }
}
#panel-events {
  height: 450px;
  .panel-group {
    margin-bottom: 0;
    height: 100%;
    overflow-y: auto;
    .panel + .panel {
      margin-top: 1px;
    }
    .panel {
      border: 0;
      @include x-border-radius(0);
      .panel-heading {
        padding: 0;
        // Panel title when closed
        a.panel-title, a.panel-title:focus, a.panel-title:visited {
          background-color: #F4F4F4;
          display: block;
          position: relative;
          color: $black;
          &:focus, &:visited {
            color: $black;
          }
          table {
            width: 100%;
            td {
              padding: 5px;
            }
          }
          .date {
            text-align: center;
            width: 100px;
            font-weight: 100;
            font-size: 26px;
          }
          .text-col {
            .title {
              font-style: italic;
              font-family: "Montserrat", Helvetica, Arial, sans-serif;
              font-size: 16px;
            }
            .time {
              font-size: 12px;
            }
          }
          .close-panel {
            width: 40px;
            text-align:center;
            background: $black;
            color: $white;
            .fa-minus {
              display: none;
            }
          }
          // Panel-title when hover
          &:hover, &:not(.collapsed) {
            background-color: $white;
            .date {
              color: $black;
            }
            .text-col {
              .time {
                color: $black;
              }
            }
          }
          // Panel-title when open
          &:not(.collapsed) {
            &:hover, &:not(.collapsed) {
              .text-col {
                .title {
                  color: $brand-danger;
                }
              }
              .close-panel {
                background-color: $brand-danger;
                .fa-minus {
                  display: block;
                }
                .fa-plus {
                  display: none;
                }
              }
            }
          }
          // Panel-title when hover
          &:hover, &:not(.collapsed):hover {
            &:hover, &:not(.collapsed) {
              .text-col {
                .title {
                  color: darken($brand-danger, 10%);
                }
              }
              .close-panel {
                background-color: darken($brand-danger, 10%);
              }
            }
          }
        }
      }
      // Panel collapse when closed
      .panel-collapse {
        background: none;
        .panel-body {
          img {
            float: left;
            margin-right: 15px;
            width: 40%;
            max-width: 115px;
          }
        }
      }
    }
  }
}

#events-search, #events-details {
  .element {
    margin-bottom: 30px;
    background-color: $white;
  }
  footer p {
    margin-bottom: 0;
    margin-top: 5px;
  }
}

// Buttons
a.link-event {
  color: $brand-danger;
  &:hover {
    color: darken($brand-danger, 10%);
  }
}

/*! Media queries */

// screen < SM
@media (max-width: $screen-xs-max) {
  #events-details .element img{
    margin-top: 15px;
  }
  #events-search, #events-details {
    footer div {
      text-align: center;
    }
  }
}