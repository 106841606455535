/*! Photo Gallery */
.photo-gallery {
  .photoswipe-gallery {
    margin: 0;
    figure{
      padding: 15px;
      a {
        display: block;
        position: relative;
      }
      .zoom-layer {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        text-align: center;
        top: 0;
        left: 0;
        color: transparent;
        background: transparent;
        @include x-transition(all 0.3s ease);
        @include x-animation(all 0.3s ease);
        .text-content {
          position: relative;
          bottom: 25%;
          top: 25%;
          left: 0;
          right: 0;
          span {
            display:block;
            margin: 10px 0;
          }
        }
      }
      &:hover {
        .zoom-layer {
          color: $white;
          background-color: $black-transparent;
        }
      }
    }
  }
}

/*! Photoswipe - Custom */
.pswp__caption__center {
  text-align: center;
}

/*! Media queries */
// screen MD and +
@media (min-width: $screen-md-min) {
  .photo-gallery .photoswipe-gallery figure:nth-child(4n+1) {
    clear:left;
  }
}
// screen SM
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .photo-gallery .photoswipe-gallery figure:nth-child(3n+1) {
    clear:left;
  }
}
// screen XS
@media (max-width: $screen-xs-max) {
  .photo-gallery .photoswipe-gallery figure:nth-child(2n+1) {
    clear:left;
  }
}