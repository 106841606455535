/*! Pages (custom) */
#page-details {
  .source, .text {
    h2 {
      margin-top: 0;
    }
    .page-image > img {
      max-width: 33%;
    }
  }
}

/*! Media queries */

// screen < SM
@media (max-width: $screen-sm-max) {
  #page-details {
    .source, .text {
      .page-image > img {
        float:none !important;
        max-width: 100%;
      }
    }
  }
}