/*! Colors */
$white: #fff;
$light-black-transparent: rgba(0,0,0,0.1);
$black-transparent: rgba(0,0,0,0.6);
$black: #333;
$green: #007749; // Pantone 3415 C
$light-green: #addc91; // Pantone 358 C
$red: #ef3340; // Pantone Red 032 C
$gray: #7c878e; // Pantone 430 C
$aubergine: #741748;
$orange: #ff7f00; // CMJN 0-50-100-0

// Override colors from psd ?
$red: #F53440;

// Body
$body-bg: #d9d9d9;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #007749;
$brand-warning: #cbb956;
$brand-danger: #F53440;

// Typography
$font-family-sans-serif: "Raleway", Helvetica, Arial, sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;
$grid-float-breakpoint: 1200px;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Pagination
$pagination-color: $white;
$pagination-bg: $black;
$pagination-border: $black-transparent;
$pagination-hover-color: $white;
$pagination-hover-bg: lighten($aubergine, 10%);
$pagination-hover-border: $black-transparent;
$pagination-active-color: $white;
$pagination-active-bg: $aubergine;
$pagination-active-border: $black-transparent;
$pagination-disabled-color: darken($white, 10%);
$pagination-disabled-bg: $black;
$pagination-disabled-border: $black-transparent;
