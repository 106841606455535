/*! Fonts */
/*! Variables */
@import "variables";
/*! Bootstrap */
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
/*! Mixins */
@import "../../../node_modules/sass-mixins/mixins.scss";
/*! Thèmes */
@import 'contact';
@import 'events';
@import 'pages';
@import 'panoramic-views';
@import 'search';
@import 'photo-gallery/default';
@import 'tarteaucitron';

/*! Backgrounds & colors */
.bg-white {
  background-color: $white;
}

.bg-light-black-transparent {
  background-color: $light-black-transparent;
}

.bg-black {
  background-color: $black;
}

.bg-black-transparent {
  background-color: $black-transparent;
}

.bg-blue {
  background-color: #008299;
}

.bg-light-green {
  background-color: #209C8A;
}

.bg-light-green-transparent {
  background-color: rgba(32, 156, 138, 0.8);
}

.white {
  color: $white;
}

.black {
  color: $black;
}

.red {
  color: $red;
}

.aubergine {
  color: $aubergine;
}

.green {
  color: $green;
}

.light-green {
  color: $light-green;
}

.btn {
  white-space: normal;
}

.btn-default, .btn-primary, .btn-info, .btn-danger, .btn-warning {
  border-width: 2px;
}

.btn-transparent-light-green, a.btn-transparent-light-green, a:visited.btn-transparent-light-green {
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  background: none;
  border: 2px solid $light-green;
  color: $light-green;
  @include x-border-radius(0);
  @include x-transition(all 0.3s ease);
  @include x-animation(all 0.3s ease);
  &:hover, &:focus, &a:hover, &a:focus {
    text-decoration: none;
    border-color: $green;
    color: $green;
  }
}

.btn-aubergine, a.btn-aubergine, a:visited.btn-aubergine {
  background-color: $aubergine;
  color: $white;
  border: 2px solid $white;
  @include x-transition(all 0.3s ease);
  @include x-animation(all 0.3s ease);
  &:hover, &:focus, &a:hover, &a:focus {
    background-color: lighten($aubergine, 10%);
    color: $white;
  }
}

.btn-white, a.btn-white, a:visited.btn-white {
  background-color: $white;
  color: #454545;
  border: 2px solid #454545;
  @include x-border-radius(0);
  @include x-transition(all 0.3s ease);
  @include x-animation(all 0.3s ease);
  &:hover, &:focus, &a:hover, &a:focus {
    border-color: #78AF00;
    color: #454545;
  }
}

/*! General */

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.v-align-mid {
  vertical-align: middle;
}

.center {
  display: block;
  margin: 0 auto;
}

.padding-0 {
  padding: 0;
}

.padding-15 {
  padding: 15px;
}

.padding-30 {
  padding: 30px;
}

.padding-tb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding-rb-15 {
  padding-right: 15px;
  padding-bottom: 15px;
}

.padding-lb-15 {
  padding-bottom: 15px;
  padding-left: 15px;
}

.padding-tb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-tb-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.margin-0 {
  margin: 0;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-left-30 {
  margin-left: 30px;
}

.italic {
  font-style: italic;
}

.big-header {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 31px;
  font-weight: bold;
  padding: 0;
  margin-top: 0;
  margin-bottom: 54px;
  display: block;
  position: relative;
}

.title-line {
  &:before, &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  &:before {
    bottom: -22px;
    width: 200px;
    border: 1px solid #CECECE;
  }
  &:after {
    bottom: -24px;
    width: 38px;
    border: 3px solid;
    color: $light-green;
  }
}

.big {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 20px;
}

.med {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 18px;
}

.small {
  font-size: 0.8em;
}

ul.dashed {
  li:before {
    content: '\2014\00a0\00a0'; /* em-dash followed by two non-breaking spaces*/
  }
  li {
    list-style: none;
    text-indent: -1.5em;
    padding-left: 1.5em;
  }
}

/*! Layout */
body {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: $black;
  font-weight: 100;
  text-align: left;
}

a {
  color: inherit;
  text-decoration: none;
  @include x-transition(all 0.3s ease);
  @include x-animation(all 0.3s ease);
  &:hover, &:focus {
    text-decoration: none;
    color: lighten($aubergine, 10%);
  }
}

#page-details {
  ul {
    overflow: hidden;
  }
  a {
    &:not(.btn) {
      text-decoration: underline;
      color: $aubergine;
      &:hover {
        text-decoration: none;
        color: lighten($aubergine, 10%);
      }
      &.thumbnail {
        text-decoration: none;
        .caption {
          color: $aubergine;
          &:hover {
            color: lighten($aubergine, 10%);
          }
        }
      }
    }
  }
}

h3 {
  color: $green;
}

hr {
  &.green {
    border-color: $light-green;
  }
}

.header-underline {
  height: 4px;
  clear: both;
  border-bottom: 1px solid #FFF;
  width: 20%;
  margin: 0 auto;
  hr {
    width: 20%;
    padding: 0;
    margin: 0 auto;
    border: 3px solid $light-green;
    vertical-align: middle;
  }
}

iframe {
  border: 0;
}

.cursor-pointer {
  cursor: pointer;
}

/*! Flash alert */
#flash-alert.with-slider {
  padding-top: 80px;
}

/*! Header */
#header {
  color: $white;
  padding-top: 116px;
  #menu-container {
    background-color: #0B524A;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0;
    border: 0;
    border-bottom: 1px solid $white;
    z-index: 1010;
  }
  /*! Slider HP */
  #slider-hp {
    max-width: 1920px;
    margin: 0 auto;
    z-index: 1;
    .carousel-caption {
      padding: 30px;
      background-color: rgba(0, 0, 0, 0.3);
      text-align: center;
      font-family: "Arima Madurai", Helvetica, Arial, sans-serif;
      font-size: 48px;
      left: 0;
      right: 0;
      bottom: 20%;
    }
    .carousel-indicators {
      left: 0;
      right: 0;
      bottom: -60px;
      margin: 0 auto;
      z-index: 2;
      width: 90%;
      li, li.active {
        width: 100px;
        height: 100px;
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0 15px;
        .thumbnail {
          background-color: transparent;
          border: none;
          padding: 0;
          margin: 0;
        }
      }
      li .thumbnail img {
        border: 5px solid $gray;
        width: 100px;
        height: 100px;
      }
      li.active .thumbnail img {
        border: 5px solid $orange;
      }
    }
  }
  /*! Menu & nav */
  .navbar-brand {
    height: auto;
    img {
      height: auto;
      max-width: 280px;
      max-height: 77px;
    }
  }
  .navbar-toggle {
    background-color: $aubergine;
    color: $white;
    border: 1px solid $white;
    span {
      background-color: $white;
    }
  }
  #nav-main, #nav-social {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 16px;
    margin-right: 0;
    & > li > a {
      text-transform: uppercase;
      height: auto;
    }
    li > a, li > a:visited {
      background-color: rgba(0, 0, 0, 0.3);
      @include x-transition(all 0.3s ease);
      @include x-animation(all 0.3s ease);
      &:hover, &:focus {
        background-color: $aubergine;
        color: $white;
      }
    }
    ul.dropdown-menu {
      background-color: transparent;
      padding: 0;
      li > a, li > a:visited {
        background-color: rgba(0, 0, 0, 0.7);
        color: $white;
        @include x-transition(all 0.3s ease);
        @include x-animation(all 0.3s ease);
        &:hover, &:focus {
          background-color: $aubergine;
          color: $white;
        }
      }
    }
  }
  #nav-social {
    clear: right;
    margin-top: 5px;
    li > a, li > a:visited {
      background-color: transparent;
    }
    li > a.small, li > a:visited {
      background-color: #00786D;
      @include x-transition(all 0.3s ease);
      @include x-animation(all 0.3s ease);
      &:hover, &:focus {
        background-color: $aubergine;
        color: $white;
      }
    }
  }
  #nav-form {
    margin: 12px 0 0;
    padding: 0 15px 0 0;
    .form-group, button {
      float: left;
    }
    input, button {
      @include x-border-radius(0);
    }
    input {
      background-color: rgba(255, 255, 255, 0.7);
      @include x-placeholder {
        color: $gray;
      }
      color: $black;
    }
    button {
      background-color: rgba(0, 0, 0, 0.3);
      color: $white;
      border-left: 0;
      border-width: 1px;
    }
  }
}

/*! Home */
#home {
  .btn-container {
    text-align: center;
    position: relative;
    margin-top: -20px;
    margin-bottom: 30px
  }
  .block-content {
    @include x-transition(all 0.3s ease);
    @include x-animation(all 0.3s ease);
    background-color: $white;
    color: $black;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    margin-top: 45px;
    padding: 15px 15px 30px;
    ul {
      padding-top: 15px;
      padding-bottom: 15px;
      > li {
        margin-bottom: 10px;
      }
    }
    .img-block {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      background-color: $white;
      width: 90px;
      height: 90px;
      margin: 0 auto;
      border: 2px solid #78AF00;
      background-position: center center;
      background-repeat: no-repeat;
      @include x-border-radius(50%);
      &.arrow {
        background-image: url("../images/icon-arrow.png");
      }
      &.protect {
        background-image: url("../images/icon-protect.png");
      }
      &.compass {
        background-image: url("../images/icon-compass.png");
      }
      &.question {
        background-image: url("../images/icon-question.png");
      }
    }
    h2 {
      margin-top: 45px;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-size: 24px;
      color: #454545;
    }
    .icon-arrow {
      background-image: url('../images/ul-arrow-gray.png');
      background-position: center center;
      background-repeat: no-repeat;
      display: inline-block;
      height: 10px;
      width: 22px;
      margin-right: 5px;
      font-size: 1px;
    }
    &:hover {
      background-color: #34B5CF;
      color: $white;
      ul > li a {
        color: $white;
        &:visited, &:focus {
          color: $white;
        }
        &:hover {
          color: $aubergine;
        }
      }
      h2 {
        color: $white;
      }
      .img-block {
        border-color: $white;
        background-color: $aubergine;
        &.arrow {
          background-image: url("../images/icon-arrow-hover.png");
        }
        &.protect {
          background-image: url("../images/icon-protect-hover.png");
        }
        &.compass {
          background-image: url("../images/icon-compass-hover.png");
        }
        &.question {
          background-image: url("../images/icon-question-hover.png");
        }
      }
      .icon-arrow {
        background-image: url('../images/ul-arrow-white.png');
      }
    }
  }
}

/*! Google map - Footer */
#map {
  max-height: 170px;
}

/*! Profile selection */
html#profile-selection-layout {
  background-color: $green;
  background-image: url("../images/P1040811.JPG");
  background-repeat: no-repeat;
  background-position: top center;
  @include x-background-size(cover);
  height: 100%;
  body {
    color: $white;
    min-height: 100%;
    background-color: transparent;
    background-image: -webkit-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
    background-image: -moz-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
    background-image: -ms-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
    background-image: repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
    @include x-background-size(2px 2px);
    #logo img {
      max-width: 280px;
    }
    #profile-selection {
      hr {
        border-color: lighten(#209C8A, 10);
      }
      .profiles-buttons {
        .btn-profile {
          display: block;
          border-radius: 0;
          margin-bottom: 15px;
          text-transform: uppercase;
          color: $white;
          white-space: normal;
          font-weight: bold;
          &:nth-child(1) {
            background-color: #005447;
            &:hover {
              background-color: darken(#005447, 10);
            }
          }
          &:nth-child(2) {
            background-color: #9F1F63;
            &:hover {
              background-color: darken(#9F1F63, 10);
            }
          }
          &:nth-child(3) {
            background-color: #F7931D;
            &:hover {
              background-color: darken(#F7931D, 10);
            }
          }
        }
      }
    }
  }

}

/*! Follow us */
#follow-us {
  background-color: #00786D;
  color: $white;
  #instagram-share-container {
    color: $black;
  }
}

/*! Footer */
#footer {
  color: $white;
  #footer-1 {
    padding-top: 45px;
    padding-bottom: 45px;
    background-color: #0B524A;
    border-top: 5px solid $orange;
    .vcard {
      font-family: "Raleway", Helvetica, Arial, sans-serif;
    }
    .social-network {
      color: $aubergine;
    }
    .list-bar {
      list-style: none;
      border-left: 2px solid $light-green;
      padding-left: 15px;
      text-transform: uppercase;
      li {
        margin: 5px;
      }
    }
  }
  #footer-2 {
    background-color: #0A3D38;
    p {
      margin: 40px 0 0 0;
    }
    .padding-bottom {
      padding-bottom: 5px;
    }
  }
  #footer-3 {
    background-color: #9CBBB1;
    color: #00786D;
    p {
      margin: 30px 0 0 0;
    }
    .separator {
      padding-top: 15px;
      clear: both;
    }
  }
}

/*! Back To Top */
.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: none;
  z-index: 9;
}

/*! Media queries */
// screen <= SM
@media (max-width: $screen-sm-max) {
  // General
  .margin-top-sm-15 {
    margin-top: 15px;
  }
  .margin-top-sm-30 {
    margin-top: 30px;
  }
  .margin-bottom-sm-15 {
    margin-bottom: 15px;
  }

  // Home
  #home {
    padding-top: 0;
    .block-content .img-block {
      top: -45px;
    }
    .btn-container {
      margin-bottom: 75px;
    }
  }

  // Footer
  #footer {
    text-align: center;
    #footer-2 {
      p {
        margin: 0;
        padding-bottom: 0;
      }
      img {
        margin: 15px auto;
      }
    }
    #footer-3 {
      p {
        margin: 0;
        padding-bottom: 0;
      }
      img {
        margin: 15px auto;
      }
    }
  }

  // Follow us
  #follow-us {
    text-align: center;
    .block-content {
      display: inline-block;
    }
  }

}

// Screen < 1200px
@media (max-width: 1199px) {
  // Bootstrap menu custom theme
  #header {
    .navbar-brand img {
      max-width: 280px;
    }
    #menu-container {
      #nav-main, #nav-social {
        background: $black;
        margin-right: -15px;
      }
      #nav-social, #nav-form {
        text-align: center;
      }
      #nav-form {
        border: 0;
        @include box-shadow(none);
        .search-block {
          display: inline-block;
        }
      }
    }
  }
}

a.dailymotion-link {
  position: relative;

  .fa-dailymotion:before {
    content: url("../images/dailymotion-brands-light.svg");
    display: block;
    width: 21px;
  }

  &:hover, &:focus {
    .fa-dailymotion:before {
      content: url("../images/dailymotion-brands-purple.svg");
    }
  }
}

.dailymotion_player {
  position: relative;
  padding-bottom: 56.25%;
  min-height: 270px;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
