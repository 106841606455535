/*! Search */
#search {
  .nav-pills > li > a {
    border-radius: 0;
    .badge {
      background-color: $white;
      color: $black;
    }
  }
  .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    background-color: $green;
    .badge {
      background-color: $white;
      color: $green;
    }
  }
  .nav > li > a:hover, .nav > li > a:focus {
    background-color: $aubergine;
    color: $white;
    .badge {
      background-color: $white;
      color: $aubergine;
    }
  }
}