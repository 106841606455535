$tarteaucitron-text-color: #fff !default;
$tarteaucitron-text-font-family: Verdana, sans-serif !default;
$tarteaucitron-manager-icon-width: 50px !default;
$tarteaucitron-alert-big-border-radius: 20px !default;
$tarteaucitron-alert-big-background: #333 !default;
$tarteaucitron-alert-big-width: 860px !default;
$tarteaucitron-alert-big-logo-max-width: 265px !default;
$tarteaucitron-alert-big-box-shadow: 0 0 9000px #000 !default;
$tarteaucitron-alert-big-before-background: #eee !default;
$tarteaucitron-alert-big-allowed-button-color: #fff !default;
$tarteaucitron-alert-big-allowed-button-background: #1B870B !default;
$tarteaucitron-alert-big-denied-button-color: #fff !default;
$tarteaucitron-alert-big-denied-button-background: #9C1A1A !default;
$tarteaucitron-services-before-background: #eee !default;
$tarteaucitron-services-width: 860px !default;
$tarteaucitron-services-main-line-background: #333 !default;
$tarteaucitron-services-line-background: rgba(51, 51, 51, 0.1) !default;
$tarteaucitron-services-line-color: #333 !default;
$tarteaucitron-allowed-button-color: #fff !default;
$tarteaucitron-allowed-button-background: #1B870B !default;
$tarteaucitron-denied-button-color: #fff !default;
$tarteaucitron-denied-button-background: #9C1A1A !default;

:root {
  --text-color: #{$tarteaucitron-text-color};
  --text-font-family: #{$tarteaucitron-text-font-family};
  --manager-icon-width: #{$tarteaucitron-manager-icon-width};
  --alert-big-border-radius: #{$tarteaucitron-alert-big-border-radius};
  --alert-big-background: #{$tarteaucitron-alert-big-background};
  --alert-big-width: #{$tarteaucitron-alert-big-width};
  --alert-big-logo-max-width: #{$tarteaucitron-alert-big-logo-max-width};
  --alert-big-box-shadow: #{$tarteaucitron-alert-big-box-shadow};
  --alert-big-before-background: #{$tarteaucitron-alert-big-before-background};
  --alert-big-allowed-button-background: #{$tarteaucitron-alert-big-allowed-button-background};
  --alert-big-allowed-button-color: #{$tarteaucitron-alert-big-allowed-button-color};
  --alert-big-denied-button-background: #{$tarteaucitron-alert-big-denied-button-background};
  --alert-big-denied-button-color: #{$tarteaucitron-alert-big-denied-button-color};
  --services-width: #{$tarteaucitron-services-width};
  --services-before-background: #{$tarteaucitron-services-before-background};
  --services-main-line-background: #{$tarteaucitron-services-main-line-background};
  --services-line-background: #{$tarteaucitron-services-line-background};
  --services-line-color: #{$tarteaucitron-services-line-color};
  --allowed-button-background: #{$tarteaucitron-allowed-button-background};
  --allowed-button-color: #{$tarteaucitron-allowed-button-color};
  --denied-button-background: #{$tarteaucitron-denied-button-background};
  --denied-button-color: #{$tarteaucitron-denied-button-color};

  font-family: var(--text-font-family);

  .modal-open {
    overflow: hidden;
    height: 100%;
  }

  #contentWrapper {
    display: unset;
  }

  /***
  * Reset CSS
  */

  #tarteaucitronRoot {
    &.tarteaucitronBeforeVisible:before {
      background: var(--services-before-background);
      opacity: 0.5;
    }

    div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;

      /*background: initial;*/
      text-align: initial;
      text-shadow: initial;
    }

    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
      display: block;
    }

    ol, ul {
      list-style: none;
    }

    blockquote, q {
      quotes: none;
    }

    blockquote {
      &:before, &:after {
        content: '';
        content: none;
      }
    }

    q {
      &:before, &:after {
        content: '';
        content: none;
      }
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    a:focus, button:focus {
      /*outline: 2px solid #cb3333;*/
    }
  }

  /* HTML5 display-role reset for older browsers */

  /***
   * Better scroll management
   */

  div {
    &#tarteaucitronMainLineOffset {
      margin-top: 0 !important;
    }

    &#tarteaucitronServices {
      margin-top: 21px !important;
    }
  }

  #tarteaucitronServices {
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      outline: 0px solid slategrey;
    }
  }

  div#tarteaucitronServices {
    box-shadow: 0 0 35px #575757;
  }

  /***
   * Responsive layout for the control panel
   */
  @media screen and (max-width: 479px) {
    #tarteaucitron .tarteaucitronLine {
      .tarteaucitronName {
        width: 90% !important;
      }

      .tarteaucitronAsk {
        float: left !important;
        margin: 10px 15px 5px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
      background: #fff;
      border: 0 !important;
      bottom: 0 !important;
      height: 100% !important;
      left: 0 !important;
      margin: 0 !important;
      max-height: 100% !important;
      max-width: 100% !important;
      top: 0 !important;
      width: 100% !important;
    }

    #tarteaucitron {
      background: #fff;
      border: 0 !important;
      bottom: 0 !important;
      height: 100% !important;
      left: 0 !important;
      margin: 0 !important;
      max-height: 100% !important;
      max-width: 100% !important;
      top: 0 !important;
      width: 100% !important;

      .tarteaucitronBorder {
        border: 0 !important;
      }
    }

    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
      border: 0 !important;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
      text-align: left !important;
    }

    .tarteaucitronName .tarteaucitronH2 {
      max-width: 80%;
    }

    #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
      text-align: center !important;

      button {
        margin-bottom: 5px;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    #tarteaucitron {
      border: 0 !important;
      left: 0 !important;
      margin: 0 5% !important;
      max-height: 80% !important;
      width: 90% !important;
    }
  }

  /***
   * Common value
   */

  #tarteaucitron * {
    zoom: 1;
  }

  #tarteaucitronRoot {
    div#tarteaucitron {
      left: 0;
      right: 0;
      margin: auto;
    }

    button#tarteaucitronBack {
      background: var(--services-before-background);
    }
  }

  #tarteaucitron {
    .clear {
      clear: both;
    }

    a {
      color: rgb(66, 66, 66);
      font-size: 11px;
      font-weight: 700;
      text-decoration: none;
    }
  }

  #tarteaucitronRoot button {
    background: transparent;
    border: 0;
  }

  #tarteaucitronAlertBig strong, #tarteaucitronAlertSmall strong, #tarteaucitronAlertBig a, #tarteaucitronAlertSmall a {
    color: #fff;
  }

  #tarteaucitron {
    strong {
      font-size: 22px;
      font-weight: 500;
    }

    ul {
      padding: 0;
    }

    .tarteaucitronH1, .tarteaucitronH2, .tarteaucitronH3, .tarteaucitronH4, .tarteaucitronH5, .tarteaucitronH6 {
      display: block;
    }
  }

  .cookie-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  /***
   * Root div added just before </body>
   */

  #tarteaucitronRoot {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    * {
      box-sizing: initial;
      color: #333;
      font-family: var(--text-font-family) !important;
      font-size: 14px;
      line-height: normal;
      vertical-align: initial;
    }

    .tarteaucitronH1 {
      font-size: 1.5em;
      text-align: center;
      color: var(--text-color);
      margin: 15px 0 28px;
    }

    .tarteaucitronH2 {
      display: inline-block;
      margin: 12px 0 0 10px;
      color: var(--text-color);
    }
  }

  #tarteaucitronCookiesNumberBis.tarteaucitronH2 {
    margin-left: 0;
  }

  /***
   * Control panel
   */

  #tarteaucitronBack {
    background: #fff;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2147483646;
  }

  #tarteaucitron {
    display: none;
    max-height: 80%;
    left: 50%;
    margin: 0 auto 0 -430px;
    padding: 0;
    position: fixed;
    top: 6%;
    max-width: 90vw;
    width: var(--services-width);
    z-index: 2147483647;

    .tarteaucitronBorder {
      background: #fff;
      border: 2px solid var(--services-main-line-background);
      border-top: 0;
      height: auto;
      overflow: auto;
    }
  }

  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie {
    background: var(--services-main-line-background);
    color: var(--text-color);
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    padding: 4px 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: 70px;
  }

  #tarteaucitron {
    #tarteaucitronClosePanel {
      background: var(--services-main-line-background);
      color: var(--text-color);
      cursor: pointer;
      font-size: 12px;
      font-weight: 700;
      text-decoration: none;
      padding: 4px 0;
      position: absolute;
      right: 0;
      text-align: center;
      width: 70px;
    }

    #tarteaucitronDisclaimer {
      color: #555;
      font-size: 12px;
      margin: 15px auto 0;
      width: 80%;
    }
  }

  .tarteaucitronSelfLink, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, #tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
    background: rgba(51, 51, 51, 0.07);
  }

  a.tarteaucitronSelfLink {
    text-align: center !important;
    display: block;
    padding: 7px !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
    display: none;
    position: relative;
  }

  #tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
    width: 100%;
    box-sizing: border-box;
  }

  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    margin: 20px 0px 0px;
    padding: 5px 20px;
    text-align: left;
    width: auto;
    background: #333;
  }

  #tarteaucitron {
    #tarteaucitronServices .tarteaucitronTitle button, #tarteaucitronInfo {
      color: var(--text-color);
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      margin: 20px 0 0;
      padding: 5px 20px;
      text-align: left;
      width: auto;
      background: var(--services-main-line-background);
    }

    #tarteaucitronServices {
      .tarteaucitronDetails {
        color: var(--text-color);
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        margin: 20px 0 0;
        padding: 5px 20px;
        text-align: left;
        width: auto;
        background: #333;
      }

      .tarteaucitronMainLine .tarteaucitronName a, .tarteaucitronTitle a {
        color: #fff;
        font-weight: 500;
      }

      .tarteaucitronMainLine .tarteaucitronName a:hover, .tarteaucitronTitle a:hover {
        text-decoration: none !important;
      }

      .tarteaucitronMainLine .tarteaucitronName a {
        font-size: 22px;
      }

      .tarteaucitronTitle a {
        font-size: 14px;
      }
    }
  }

  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
    padding: 5px 10px;
    margin: 0;
  }

  #tarteaucitron {
    #tarteaucitronInfo, #tarteaucitronServices .tarteaucitronDetails {
      color: var(--text-color);
      display: none;
      font-size: 12px;
      font-weight: 500;
      margin-top: 0;
      max-width: 270px;
      padding: 20px;
      position: absolute;
      z-index: 2147483647;
    }

    #tarteaucitronInfo a {
      color: #fff;
      text-decoration: underline;
    }

    #tarteaucitronServices {
      .tarteaucitronLine {
        background: var(--services-line-background);
        border-left: 5px solid transparent;
        margin: 0;
        overflow: hidden;
        padding: 15px 0;
      }

      .tarteaucitronMainLine {
        background: var(--services-main-line-background);
        margin-bottom: 0;
        margin-top: 21px;
        position: relative;
        border: none;

        &:hover {
          background: var(--services-main-line-background);
        }

        .tarteaucitronName {
          margin-left: 15px;
          margin-top: 2px;
          color: var(--services-line-color);

          button {
            color: var(--text-color);
          }
        }

        .tarteaucitronAsk {
          margin-top: 0px !important;
        }
      }

      .tarteaucitronLine {
        .tarteaucitronName {
          display: inline-block;
          float: left;
          margin-left: 10px;
          text-align: left;
          width: 50%;
          color: var(--services-line-color);

          a:hover {
            text-decoration: underline;
          }
        }

        .tarteaucitronAsk {
          display: inline-block;
          float: right;
          margin: 7px 15px 0;
          text-align: right;

          .tarteaucitronAllow, .tarteaucitronDeny {
            background: gray;
            border-radius: 4px;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            padding: 6px 10px;
            text-align: center;
            text-decoration: none;
            width: auto;
            border: 0;
          }
        }
      }
    }
  }

  .tac_activate .tarteaucitronAllow {
    background: gray;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding: 6px 10px;
    text-align: center;
    text-decoration: none;
    width: auto;
    border: 0;
  }

  #tarteaucitron {
    #tarteaucitronServices {
      #tarteaucitronAllAllowed.tarteaucitronIsSelected {
        background-color: var(--allowed-button-background);
        opacity: 1;
        color: var(--allowed-button-color);
      }

      #tarteaucitronAllDenied.tarteaucitronIsSelected, #tarteaucitronAllDenied2.tarteaucitronIsSelected {
        background-color: var(--denied-button-background);
        color: var(--denied-button-color);
        opacity: 1;
      }

      .tarteaucitronLine {
        .tarteaucitronH3, a {
          color: var(--services-line-color);
        }

        &.tarteaucitronIsAllowed .tarteaucitronAllow {
          background-color: var(--allowed-button-background);
          color: var(--allowed-button-color);
        }

        &.tarteaucitronIsDenied .tarteaucitronDeny {
          background-color: var(--denied-button-background);
          color: var(--denied-button-color);
        }

        .tarteaucitronName .tarteaucitronListCookies {
          color: var(--services-line-color);
          font-size: 12px;
        }
      }
    }

    .tarteaucitronH3 {
      font-size: 18px;
    }

    #tarteaucitronMainLineOffset .tarteaucitronName {
      width: auto !important;
      margin-left: 0 !important;
      font-size: 14px;
    }
  }

  /***
   * Big alert
   */

  .tarteaucitronAlertBigTop {
    top: 0;
  }

  .tarteaucitronAlertBigBottom {
    bottom: 0;
  }

  #tarteaucitronRoot #tarteaucitronAlertBig {
    background: var(--alert-big-background);
    color: #fff;
    display: none;
    font-size: 15px !important;
    position: fixed;
    box-sizing: content-box;
    z-index: 2147483645;
    text-align: center;
    margin: auto;
    max-width: 90vw;
    border-radius: var(--alert-big-border-radius);
    width: var(--alert-big-width);
    box-shadow: var(--alert-big-box-shadow);

    .privacy-url-block {
      text-align: center;
      margin-top: 10px;
    }

    #tarteaucitronMiddleBarLogo {
      max-width: var(--alert-big-logo-max-width);
      margin: 0 auto;

      img {
        max-width: 100%;
      }
    }
  }

  #tarteaucitronAlertBig {
    #tarteaucitronPrivacyUrl, #tarteaucitronPrivacyUrlDialog {
      font-size: 15px;
      font-family: var(--text-font-family);
      color: var(--text-color);
    }

    #tarteaucitronDisclaimerAlert {
      font-size: 15px;
      font-family: var(--text-font-family);
      color: var(--text-color);

      strong {
        font-weight: 700;
      }

      a {
        text-decoration: underline;
      }
    }

    #tarteaucitronPrivacyUrl, #tarteaucitronPrivacyUrlDialog {
      cursor: pointer;
    }

    #tarteaucitronAllow {
      background: var(--alert-big-allowed-button-background);
      color: var(--alert-big-allowed-button-color);
    }

    #tarteaucitronDenied {
      background: var(--alert-big-denied-button-background);
      color: var(--alert-big-denied-button-color);
    }

    #tarteaucitronCloseAlert, #tarteaucitronPersonalize, #tarteaucitronPersonalize2 {
      background: var(--alert-big-allowed-button-background);
      color: var(--alert-big-allowed-button-color);
      cursor: pointer;
      display: inline-block;
      font-size: 16px !important;
      line-height: 1.2;
      padding: 5px 10px;
      text-decoration: none;
      margin-left: 7px;
    }
  }

  .tarteaucitronCTAButton {
    background: var(--allowed-button-background);
    color: var(--allowed-button-color);
    cursor: pointer;
    display: inline-block;
    font-size: 16px !important;
    line-height: 1.2;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 7px;
  }

  #tarteaucitron {
    #tarteaucitronPrivacyUrl, #tarteaucitronPrivacyUrlDialog {
      background: var(--allowed-button-background);
      color: var(--allowed-button-color);
      cursor: pointer;
      display: inline-block;
      font-size: 16px !important;
      line-height: 1.2;
      padding: 5px 10px;
      text-decoration: none;
      margin-left: 7px;
    }
  }

  #tarteaucitronRoot .tarteaucitronDeny {
    cursor: pointer;
    display: inline-block;
    font-size: 16px !important;
    line-height: 1.2;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 7px;
    background: var(--denied-button-background);
    color: var(--denied-button-color);
  }

  #tarteaucitronAlertBig #tarteaucitronCloseAlert {
    background: #fff;
    color: #333;
    font-size: 13px;
    margin-bottom: 3px;
    margin-left: 7px;
    padding: 5px 10px;
  }

  #tarteaucitron {
    #tarteaucitronPrivacyUrl, #tarteaucitronPrivacyUrlDialog {
      background: #fff;
      color: #333;
      font-size: 13px;
      margin-bottom: 3px;
      margin-left: 7px;
      padding: 5px 10px;
    }
  }

  #tarteaucitronPercentage {
    background: #0A0 !important;
    box-shadow: 0 0 2px #fff, 0 1px 2px #555;
    height: 5px;
    left: 0;
    position: fixed;
    width: 0;
    z-index: 2147483644;
  }

  /***
   * Icon
   */

  .tarteaucitronIconBottomRight {
    bottom: 0;
    right: 0;
  }

  .tarteaucitronIconBottomLeft {
    bottom: 0;
    left: 0;
  }

  .tarteaucitronIconTopRight {
    top: 0;
    right: 0;
  }

  .tarteaucitronIconTopLeft {
    top: 0;
    left: 0;

    #tarteaucitronManager {
      border-radius: 2px 7px 7px 2px;
    }
  }

  .tarteaucitronIconTopRight #tarteaucitronManager {
    border-radius: 7px 2px 2px 7px;
  }

  .tarteaucitronIconBottomLeft #tarteaucitronManager, .tarteaucitronIconBottomRight #tarteaucitronManager {
    border-radius: 7px 7px 2px 2px;
  }

  #tarteaucitronIcon {
    background: transparent;
    position: fixed;
    display: none;
    width: auto;
    z-index: 2147483646;

    #tarteaucitronManager {
      color: transparent;
      cursor: pointer;
      display: inline-block;
      font-size: 11px !important;
      padding: 8px 10px 8px;
      border: none;

      img {
        width: var(--manager-icon-width);
      }
    }
  }

  /***
   * Small alert
   */

  .tarteaucitronAlertSmallTop, .tarteaucitronAlertSmallBottom {
    bottom: 0;
  }

  #tarteaucitronAlertSmall {
    background: #333;
    display: none;
    padding: 0;
    position: fixed;
    right: 0;
    text-align: center;
    width: auto;
    z-index: 2147483646;

    #tarteaucitronManager {
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 11px !important;
      padding: 8px 10px 8px;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }

      #tarteaucitronDot {
        background-color: gray;
        border-radius: 5px;
        display: block;
        height: 8px;
        margin-bottom: 1px;
        margin-top: 5px;
        overflow: hidden;
        width: 100%;

        #tarteaucitronDotGreen, #tarteaucitronDotYellow, #tarteaucitronDotRed {
          display: block;
          float: left;
          height: 100%;
          width: 0%;
        }

        #tarteaucitronDotGreen {
          background-color: var(--allowed-button-background);
          color: var(--allowed-button-color);
        }

        #tarteaucitronDotYellow {
          background-color: #FBDA26;
        }

        #tarteaucitronDotRed {
          background-color: var(--denied-button-background);
          color: var(--allowed-button-color);
        }
      }
    }

    #tarteaucitronCookiesNumber {
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 30px;
      padding: 0px 10px;
      vertical-align: top;

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }

    #tarteaucitronCookiesListContainer {
      display: none;
      max-height: 70%;
      max-width: 500px;
      position: fixed;
      right: 0;
      width: 100%;

      #tarteaucitronCookiesList {
        background: #fff;
        border: 2px solid #333;
        color: #333;
        font-size: 11px;
        height: auto;
        overflow: auto;
        text-align: left;

        strong {
          color: #333;
        }
      }

      #tarteaucitronCookiesTitle {
        background: #333;
        margin-top: 21px;
        padding: 13px 0 9px 13px;
        text-align: left;

        strong {
          color: #fff;
          font-size: 16px;
        }
      }

      #tarteaucitronCookiesList .tarteaucitronCookiesListMain {
        background: rgba(51, 51, 51, 0.1);
        padding: 7px 5px 10px;
        word-wrap: break-word;

        &:hover {
          background: rgba(51, 51, 51, 0.2);
        }

        a {
          color: #333;
          text-decoration: none;
        }

        .tarteaucitronCookiesListLeft {
          display: inline-block;
          width: 50%;

          a strong {
            color: darkred;
          }
        }

        .tarteaucitronCookiesListRight {
          color: #333;
          display: inline-block;
          font-size: 11px;
          margin-left: 10%;
          vertical-align: top;
          width: 30%;
        }
      }
    }
  }

  /***
   * Fallback activate link
   */

  .tac_activate {
    background: #333;
    color: #fff;
    display: table;
    font-size: 12px;
    height: 100%;
    line-height: initial;
    margin: auto;
    text-align: center;
    width: 100%;
  }

  .tac_float {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .tac_activate .tac_float {
    strong {
      color: #fff;
    }

    .tarteaucitronAllow {
      background-color: var(--allowed-button-background);
      color: var(--allowed-button-color);
      display: inline-block;
    }
  }

  /***
   * CSS for services
   */

  ins {
    &.ferank-publicite, &.adsbygoogle {
      text-decoration: none;
    }
  }

  div.amazon_product {
    height: 240px;
    width: 120px;
  }

  .tarteaucitronIsAllowed .tarteaucitronDeny, .tarteaucitronIsDenied .tarteaucitronAllow {
    opacity: 0.4 !important;
  }

  .tarteaucitronIsAllowed .tarteaucitronAllow, .tarteaucitronIsDenied .tarteaucitronDeny {
    opacity: 1 !important;
  }

  .tarteaucitronLine {
    .tarteaucitronAllow, .tarteaucitronDeny {
      opacity: 0.4;
    }
  }

  #tarteaucitronServices_mandatory button.tarteaucitronAllow {
    opacity: 1;
  }

  div#tarteaucitronInfo {
    display: block !important;
    position: relative !important;
    text-align: center !important;
    max-width: 80% !important;
    padding: 15px 0 !important;
    margin: -10px auto 40px !important;
    font-size: 1em !important;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: #555;
  }

  a.tarteaucitronSelfLink {
    position: absolute;
    left: 0;
    right: 0;
    padding-top: 13px !important;
    display: block;
    text-shadow: 0 0 14px white;
    text-transform: uppercase;
  }

  .tarteaucitronMainLine .tarteaucitronH2 {
    font-size: 1.2em !important;
    margin-top: 4px !important;
  }

  span.tarteaucitronTitle.tarteaucitronH3 {
    margin-top: 12px !important;
  }

  .spacer-20 {
    height: 20px;
    display: block;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }
}
