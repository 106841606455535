/*! Block panoramic views */
#block-panoramic-views {
  position: relative;
  #header-block {
    position: absolute;
    color: white;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    #header-content {
      position: relative;
      display: inline-block;
      z-index: 1;
      margin: 0 auto;
      padding: 15px 30px 30px;
      background: $black-transparent;
      h2 {
        margin: 0;
      }
      h3 {
        margin: 5px;
        color: $white;
        .title-line:before {
          bottom: 15px;
        }
        .title-line:after {
          bottom: 12px;
        }
      }
    }
  }
  #panoramic-views-map {
    width: 100%;
    height: 630px;
  }
}